"use client";

import React, { useState } from "react";
import styles from "./TextInput.module.scss";

/**
 *
 * TextInput
 *
 */
const TextInput = ({
  label = "",
  name = "",
  value = "",
  placeholder = "",
  background = "bg-transparent",
  required = false,
  type = "text",
  onChange = ev => {},
  ...props
}) => {
  const [_value, setValue] = useState(value);
  return <div className={styles["text-input"]} {...props} data-sentry-component="TextInput" data-sentry-source-file="TextInput.tsx">
      <label htmlFor={name} className="sr-only">
        {label}
      </label>
      <input type={type} name={name} id={name} value={_value} placeholder={placeholder || label} className={`rounded-button border border-border-color color-border-color w-full h-[70px] flex justify-between items-center px-10 leading-none text-black ${background}`} required={required} onChange={e => {
      setValue(e.target.value);
      onChange(e);
    }} />
    </div>;
};
export default TextInput;