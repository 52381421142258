"use client";

import React, { useState, useMemo } from "react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
const DynamicTable = ({
  data
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");
  const [stcFilter, setStcFilter] = useState("all");
  const [fireFilter, setFireFilter] = useState("all");
  const itemsPerPage = 10;
  const filteredAndSortedData = useMemo(() => {
    return data.filter(item => Object.values(item).some(value => value?.toString()?.toLowerCase()?.includes(searchTerm?.toLowerCase())) && (stcFilter === "all" || item["STC Rating"] === stcFilter) && (fireFilter === "all" || item["Fire Rating"] === fireFilter)).sort((a, b) => {
      if (sortColumn === "") return 0;
      const aValue = a[sortColumn];
      const bValue = b[sortColumn];
      if (typeof aValue === "number" && typeof bValue === "number") {
        return sortDirection === "asc" ? aValue - bValue : bValue - aValue;
      }
      return sortDirection === "asc" ? aValue.toString().localeCompare(bValue.toString()) : bValue.toString().localeCompare(aValue.toString());
    });
  }, [data, searchTerm, sortColumn, sortDirection, stcFilter, fireFilter]);
  const pageCount = Math.ceil(filteredAndSortedData.length / itemsPerPage);
  const paginatedData = filteredAndSortedData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  const handleSort = column => {
    if (column === sortColumn) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };
  return <div className="space-y-4" data-sentry-component="DynamicTable" data-sentry-source-file="InteractiveTable.tsx">
      <Input type="text" placeholder="Search..." value={searchTerm} onChange={e => setSearchTerm(e.target.value)} className="w-full" data-sentry-element="Input" data-sentry-source-file="InteractiveTable.tsx" />
      <div className="flex space-x-4">
        <Select value={stcFilter} onValueChange={setStcFilter} data-sentry-element="Select" data-sentry-source-file="InteractiveTable.tsx">
          <SelectTrigger className="w-[180px]" data-sentry-element="SelectTrigger" data-sentry-source-file="InteractiveTable.tsx">
            <SelectValue placeholder="Filter STC Rating" data-sentry-element="SelectValue" data-sentry-source-file="InteractiveTable.tsx" />
          </SelectTrigger>
          <SelectContent className="bg-white" data-sentry-element="SelectContent" data-sentry-source-file="InteractiveTable.tsx">
            <SelectItem value="all" data-sentry-element="SelectItem" data-sentry-source-file="InteractiveTable.tsx">All STC Ratings</SelectItem>
            {[...new Set(data.map(item => item["STC Rating"]))].sort((a, b) => a - b).map(rating => <SelectItem key={rating} value={rating.toString()} className="hover:bg-[#f1f1f1] py-2 border-b border-[#eee]">
                  {rating}
                </SelectItem>)}
          </SelectContent>
        </Select>
        <Select value={fireFilter} onValueChange={setFireFilter} data-sentry-element="Select" data-sentry-source-file="InteractiveTable.tsx">
          <SelectTrigger className="w-[180px]" data-sentry-element="SelectTrigger" data-sentry-source-file="InteractiveTable.tsx">
            <SelectValue placeholder="Filter Fire Rating" data-sentry-element="SelectValue" data-sentry-source-file="InteractiveTable.tsx" />
          </SelectTrigger>
          <SelectContent className="bg-white" data-sentry-element="SelectContent" data-sentry-source-file="InteractiveTable.tsx">
            <SelectItem value="all" data-sentry-element="SelectItem" data-sentry-source-file="InteractiveTable.tsx">All Fire Ratings</SelectItem>
            {[...new Set(data.map(item => item["Fire Rating"]))].sort((a, b) => a - b).map(rating => <SelectItem key={rating} value={rating.toString()} className="hover:bg-[#f1f1f1] py-2 border-b border-[#eee]">
                  {rating}
                </SelectItem>)}
          </SelectContent>
        </Select>
      </div>
      <Table className="min-h-[1050px]" data-sentry-element="Table" data-sentry-source-file="InteractiveTable.tsx">
        <TableHeader data-sentry-element="TableHeader" data-sentry-source-file="InteractiveTable.tsx">
          <TableRow data-sentry-element="TableRow" data-sentry-source-file="InteractiveTable.tsx">
            {Object.keys(data[0]).map(column => <TableHead key={column} onClick={() => handleSort(column)} className="cursor-pointer">
                {column}
                {sortColumn === column && <span className="ml-1">
                    {sortDirection === "asc" ? "▲" : "▼"}
                  </span>}
              </TableHead>)}
          </TableRow>
        </TableHeader>
        <TableBody data-sentry-element="TableBody" data-sentry-source-file="InteractiveTable.tsx">
          {paginatedData.map((item, index) => <TableRow key={index}>
              {Object.values(item).map((value, valueIndex) => <TableCell key={valueIndex}>{value}</TableCell>)}
            </TableRow>)}
        </TableBody>
      </Table>
      <div className="flex justify-between items-center">
        <div>
          Page {currentPage} of {pageCount}
        </div>
        <div className="space-x-2">
          <Button onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} disabled={currentPage === 1} data-sentry-element="Button" data-sentry-source-file="InteractiveTable.tsx">
            Previous
          </Button>
          <Button onClick={() => setCurrentPage(prev => Math.min(prev + 1, pageCount))} disabled={currentPage === pageCount} data-sentry-element="Button" data-sentry-source-file="InteractiveTable.tsx">
            Next
          </Button>
        </div>
      </div>
    </div>;
};
export default DynamicTable;