import { useRef, useEffect, useCallback } from "react";
const useResizeObserver = callback => {
  const ref = useRef(null);
  const resizeObserver = useRef(null);
  const previousDimensions = useRef({
    width: 0,
    height: 0
  });
  const handleResize = entries => {
    const [entry] = entries;
    if (entry) {
      const {
        width,
        height
      } = entry.contentRect;
      // if (
      //   width !== previousDimensions.current.width ||
      //   height !== previousDimensions.current.height
      // ) {
      //   previousDimensions.current = { width, height };
      callback({
        width,
        height
      });
      // }
    }
  };
  useEffect(() => {
    // const element = ref.current;
    // if (!element) return;

    resizeObserver.current = new ResizeObserver(handleResize);
    resizeObserver.current.observe(document.documentElement);
    return () => {
      if (resizeObserver.current) {
        resizeObserver.current.disconnect();
      }
    };
  }, [handleResize]);
  return ref;
};
export default useResizeObserver;