import { useEffect, useRef, useState } from "react";
type Props = {
  onIntersect: (target: Element) => void;
  onNotIntersect: (target: Element) => void;
  threshold?: number;
};
const useIntersectionObserver = ({
  onIntersect,
  onNotIntersect,
  threshold = 0.25
}: Props) => {
  const observerRef = useRef(null);
  const [isIntersecting, setIsIntersecting] = useState(false);
  useEffect(() => {
    if (!observerRef.current) return;
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setIsIntersecting(true);
          onIntersect(entry.target);
        } else {
          setIsIntersecting(false);
          onNotIntersect(entry.target);
        }
      });
    }, {
      threshold: threshold || 0.25
    });
    if (observerRef.current) {
      observer.observe(observerRef.current);
    }
    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, [onIntersect, onNotIntersect, observerRef]);
  return {
    observerRef,
    isIntersecting
  };
};
export default useIntersectionObserver;