export const BackgroundColors = {
  Default: "bg-white",
  Black: "bg-black",
  Gray: "bg-[#e2e2e2]",
  "Light Gray": "bg-[#F2F2F2]",
};

export const BackgroundColorText = {
  Default: "text-black",
  Black: "text-white",
  Gray: "text-black",
};

export const BlockSplits = {
  "1/2": ["col-span-3", "col-span-3"],
  "1/3": ["col-span-2", "col-span-3"],
  "2/3": ["col-span-3", "col-span-2"],
};

export const Padding = {
  None: "py-0",
  Top: "pt-24",
  Bottom: "pb-24",
  "Top and Bottom": "py-24",
};

export const CategoryMap = {
  Market: "/markets",
  Solution: "/solutions",
  Product: "/products",
  "Case Study": "/case-studies",
  Series: "/series",
  Training: "/training",
  Category: "/categories",
};
