import { useEffect, useRef } from "react";

type Props = {
  onIntersect: (target: Element) => void;
  onNotIntersect: (target: Element) => void;
};

const useIntersectionObserver = ({ onIntersect, onNotIntersect }: Props) => {
  const observerRef = useRef(null);

  useEffect(() => {
    if (!observerRef.current) return;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          onIntersect(entry.target);
        } else {
          onNotIntersect(entry.target);
        }
      });
    });

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, [onIntersect, onNotIntersect, observerRef]);

  return { observerRef };
};

export default useIntersectionObserver;
