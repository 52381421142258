"use client";

import React, { useEffect, useRef, useState, useCallback } from "react";
import useEmblaCarousel from "embla-carousel-react";
import { ChevronLeftCircle, ChevronRightCircle } from "lucide-react";
import RightArrow from "@/components/common/RightArrow";
import useResizeObserver from "@/hooks/useResizeObserver";
import clsx from "clsx";
import For from "@/components/common/For";
import If from "@/components/common/If";
const NoScroll = ({
  children,
  length = 0,
  className = "",
  contentRef = null
}) => {
  return <div className={`embla overflow-hidden max-w-full w-full ${className}`} data-sentry-component="NoScroll" data-sentry-source-file="Carousel.tsx">
      <div className="relative">
        <div className="embla__viewport">
          <div className={clsx("flex justify-center mx-auto w-[100dvw] gap-16")} ref={contentRef}>
            {children}
          </div>
        </div>
      </div>
    </div>;
};
const Scrollable = ({
  children,
  length = 0,
  className = "",
  contentRef = null
}) => {
  const [viewportRef, embla] = useEmblaCarousel({
    // loop: true,
    align: "start",
    startIndex: 0,
    containScroll: false
  });
  const [currentIndex, setCurrentIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);
  const onInit = useCallback(embla => {
    setScrollSnaps(embla.scrollSnapList());
  }, []);
  useEffect(() => {
    if (embla) {
      // Initialize Embla Carousel
      embla.on("select", () => {
        // Do something on slide change
      });
      onInit(embla);
    }
  }, [embla]);
  const prevSlide = useCallback(() => {
    if (!embla) {
      return;
    }
    embla.scrollPrev();
    setCurrentIndex(embla.selectedScrollSnap());
  }, [embla]);
  const nextSlide = useCallback(() => {
    if (!embla) {
      return;
    }
    embla.scrollNext();
    setCurrentIndex(embla.selectedScrollSnap());
  }, [embla]);
  useEffect(() => {
    if (embla) {
      // console.log(title, embla);
      // Initialize Embla Carousel
      embla.on("select", () => {
        // console.log("select");
        setCurrentIndex(embla.selectedScrollSnap());
        // onChange(embla.selectedScrollSnap());
      });
      embla.on("settle", () => {
        // setSelected(embla.selectedScrollSnap());
      });
      embla.on("resize", () => {});
    }
  }, [embla]);
  return <div className={`embla overflow-hidden max-w-full w-full ${className}`} data-sentry-component="Scrollable" data-sentry-source-file="Carousel.tsx">
      <div className="relative">
        <div className="embla__viewport" ref={viewportRef}>
          <div className={clsx("embla__container flex w-full gap-8")} ref={contentRef}>
            {children}
          </div>
        </div>
      </div>
      <If condition={scrollSnaps.length > 1} data-sentry-element="If" data-sentry-source-file="Carousel.tsx">
        <div className="flex container mx-auto flex-col lg:flex-row justify-between gap-2 mt-4 items-center px-gutter">
          <div className="flex gap-2">
            <For each={scrollSnaps} render={(thumb, idx) => {
            return <button onClick={() => {
              embla?.scrollTo(idx);
              setCurrentIndex(idx);
            }} className={`border ${currentIndex === idx ? "bg-gold border-gold" : "bg-white border-white"} aspect-square w-3 h-3 overflow-hidden`}>
                    <span className="sr-only">{idx}</span>
                  </button>;
          }} data-sentry-element="For" data-sentry-source-file="Carousel.tsx" />
          </div>
          <div className="gap-2 hidden md:flex">
            <button className="embla__button embla__button--prev" onClick={prevSlide}>
              <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="Carousel.tsx">
                <circle cx="16.5" cy="16.5002" r="16.5" transform="rotate(176.915 16.5 16.5002)" fill="#B18626" data-sentry-element="circle" data-sentry-source-file="Carousel.tsx" />
                <path d="M20.4523 24.7993L11.3102 16.9103L19.5515 8.08445" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="Carousel.tsx" />
              </svg>
            </button>
            <button className="embla__button embla__button--next " onClick={nextSlide}>
              <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="Carousel.tsx">
                <circle cx="16.5" cy="16.5" r="16.5" fill="#B18626" data-sentry-element="circle" data-sentry-source-file="Carousel.tsx" />
                <path d="M13 8L21.7043 16.3696L13 24.7391" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="Carousel.tsx" />
              </svg>
            </button>
          </div>
        </div>
      </If>
    </div>;
};
const Carousel = ({
  children,
  length = 0,
  className = ""
}) => {
  const [centerIt, setCenterIt] = useState(false);
  const contentRef = useRef(null);
  const handleResize = () => {
    if (contentRef.current) {
      const firstChild = contentRef.current.firstChild;
      const fullWidth = firstChild?.offsetWidth * length + length * 32 + 32;
      if (fullWidth < window.innerWidth) {
        setCenterIt(true);
        // console.log("Center it", true);
      } else if (centerIt) {
        setCenterIt(false);
        // console.log("Center it", false);
      }
    }
  };
  const ref = useResizeObserver(handleResize);
  return <div className="relative" ref={ref} data-sentry-component="Carousel" data-sentry-source-file="Carousel.tsx">
      <If condition={centerIt} data-sentry-element="If" data-sentry-source-file="Carousel.tsx">
        <NoScroll contentRef={contentRef} data-sentry-element="NoScroll" data-sentry-source-file="Carousel.tsx">{children}</NoScroll>
      </If>
      <If condition={!centerIt} data-sentry-element="If" data-sentry-source-file="Carousel.tsx">
        <Scrollable contentRef={contentRef} data-sentry-element="Scrollable" data-sentry-source-file="Carousel.tsx">{children}</Scrollable>
      </If>
    </div>;
};
export default Carousel;