import React from "react";
import styles from "./Heading.module.scss";
import AnimateTitle from "@/components/common/AnimateTitle";

/**
 *
 * Heading
 *
 */
const Heading = ({
  as = "h2",
  textSize = "text-h2",
  bottomBorder = false,
  align = "left",
  isContainer = false,
  children,
  ...props
}: any) => {
  return <div className={`${isContainer ? "container mx-auto" : ""}`} data-sentry-component="Heading" data-sentry-source-file="Heading.tsx">
      <AnimateTitle as={as} title={children} className={`${styles.heading} ${textSize} ${align === "center" ? "justify-center" : ""} font-hero font-400 ${bottomBorder ? "pb-12 border-b-2 border-[#96A2A1]" : ""}`} {...props} data-sentry-element="AnimateTitle" data-sentry-source-file="Heading.tsx" />
    </div>;
};
export default Heading;