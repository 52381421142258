import React from "react";
import styles from "./Tooltip.module.scss";
import * as RadixTooltip from "@radix-ui/react-tooltip";

/**
 *
 * Tooltip
 *
 */
const Tooltip = ({
  children,
  text = "",
  ...props
}) => {
  return <RadixTooltip.Provider data-sentry-element="unknown" data-sentry-component="Tooltip" data-sentry-source-file="Tooltip.tsx">
      <RadixTooltip.Root data-sentry-element="unknown" data-sentry-source-file="Tooltip.tsx">
        <RadixTooltip.Trigger asChild data-sentry-element="unknown" data-sentry-source-file="Tooltip.tsx">{children}</RadixTooltip.Trigger>
        <RadixTooltip.Portal data-sentry-element="unknown" data-sentry-source-file="Tooltip.tsx">
          <RadixTooltip.Content className="bg-black text-white p-2 rounded-md shadow-md z-[150]" sideOffset={5} data-sentry-element="unknown" data-sentry-source-file="Tooltip.tsx">
            {text}
            <RadixTooltip.Arrow className={styles.arrow} data-sentry-element="unknown" data-sentry-source-file="Tooltip.tsx" />
          </RadixTooltip.Content>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>;
};
export default Tooltip;